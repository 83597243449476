import { useAuth0 } from "../react-auth0-spa";
import { useMutation, useQueries, useQuery } from "react-query";
import queryClient from "../../lib/queryClient";
import { useNotification } from "../../ui/utils/notification/notification2";
import { chainError, useGet, usePost } from "../util";
import { ERROR_NOT_FOUND } from "../constants/errors";
import { usePatch } from "../util";
import { Invoices } from "../types/invoice";
import { Invoice, PaymentMethod } from "../../lib/types/invoice";
import { InvoiceApprover } from "../../lib/types/approval";
import { PaymentsWrapper } from "../../lib/types/payment";
import { QbTaxCode } from "../types/qb";
import { InvoicesMetadata } from "../types/metadata";
import { labelsFromNanonets } from "../constants/invoice";

export const useSelectedInvoices = (invoiceIds: string[], teamId: string) => {
  const get = useGet();

  return useQuery<Invoice[], Error>(
    ["invoiceList", invoiceIds.sort().join()],
    () => {
      return Promise.all(
        invoiceIds.map((invoiceId) =>
          get(`invoices/details/${invoiceId}?teamid=${teamId}`)
        )
      );
    },
    {
      onSuccess(data) {
        data.forEach((invoice) => {
          queryClient.setQueryData(["invoice", invoice.Id], invoice);
        });
      },
    }
  );
};

export const useInvoice = (invoiceId, currentTeam) => {
  const { getTokenSilently } = useAuth0();
  return useQuery<any, Error>(["invoice", invoiceId], async () => {
    const token = await getTokenSilently();
    return fetch(
      `/api/v1/invoices/details/${invoiceId}?teamid=${currentTeam}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) =>
      res.ok
        ? res.json()
        : Promise.reject(
            res.status === 404
              ? ERROR_NOT_FOUND
              : new Error("Error while fetching integrations")
          )
    );
  });
};

export const useInvoices = (currentTeam, params, enabled = true) => {
  const { getTokenSilently } = useAuth0();
  return useQuery<Invoices, Error>(
    ["invoices", currentTeam, params],
    async () => {
      const token = await getTokenSilently();
      return fetch(`/api/v1/invoices?teamid=${currentTeam}${params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Error while fetching integrations"))
      );
    },
    {
      enabled,
    }
  );
};

export const useInvoicesMetadata = (teamId: string) => {
  const get = useGet();
  return useQuery<InvoicesMetadata, Error>(["invoicesMetadata", teamId], () =>
    get(`invoices/metadata?teamid=${teamId}`).catch(
      chainError("Error while fetching invoices metadata")
    )
  );
};

export const usePatchInvoice = (currentTeam) => {
  const patch = usePatch();
  const notification = useNotification();
  return useMutation<any, Error, {}>(
    async (request) => {
      return patch(`invoices?teamid=${currentTeam}`, request);
    },
    {
      onSuccess: (data) => {
        notification.success("Moved successfully");
        queryClient.invalidateQueries(["invoices", currentTeam]);
        queryClient.invalidateQueries(["spends"]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const usePatchSingleInvoice = (teamId: string) => {
  const patch = usePatch();
  const notification = useNotification();
  return useMutation<
    void,
    Error,
    { request: { key: string; value: any }[]; invoiceId: string }
  >(
    ({ request, invoiceId }) =>
      patch(`invoices/${invoiceId}/?teamid=${teamId}`, request),
    {
      onSuccess: (_, { invoiceId }) => {
        queryClient.invalidateQueries(["spends"]);

        queryClient.invalidateQueries(["invoice", invoiceId]);
        queryClient.invalidateQueries(["invoiceApprovers", invoiceId]);
        queryClient.invalidateQueries(["invoicesMetadata", teamId]);
        queryClient.invalidateQueries(["invoices", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useUpdateInvoiceStatus = (currentTeam, id) => {
  const notification = useNotification();
  const { getTokenSilently } = useAuth0();
  return useMutation<any, Error, { invoice: Invoice }>(
    async ({ invoice }) => {
      const token = await getTokenSilently();
      return fetch(
        `/api/v1/invoices/updateStatusPaid/${id}?teamid=${currentTeam}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(invoice),
        }
      ).then((res) =>
        res.ok
          ? res.text()
          : Promise.reject(new Error("Error while updating invoice"))
      );
    },
    {
      onSuccess: (data) => {
        notification.success("Paid successfully");
        queryClient.invalidateQueries(["invoice", id]);
        queryClient.invalidateQueries(["invoices", currentTeam]);
        queryClient.invalidateQueries(["spends"]);
      },
      onError: (error) => {
        console.error(error.message);
        notification.error(error.message);
      },
    }
  );
};

export const useSendPoToVendor = (teamId: string) => {
  const notification = useNotification();
  const patch = usePatch();
  return useMutation<void, Error, string>(
    (id) =>
      patch(`invoices/${id}/sendToVendor?teamid=${teamId}`).catch(
        chainError("Error while sending PO to vendor")
      ),
    {
      onSuccess: (_, id) => {
        notification.success("PO sent to vendor successfully");
        queryClient.invalidateQueries(["invoice", id]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useApproveInvoice = (currentTeam, invoiceId) => {
  const notification = useNotification();
  const { getTokenSilently } = useAuth0();
  return useMutation<any, Error, { invoiceApprover: any }>(
    async ({ invoiceApprover }) => {
      const token = await getTokenSilently();
      return fetch(
        `/api/v1/invoices/${invoiceId}/approve?teamid=${currentTeam}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(invoiceApprover),
        }
      ).then((res) =>
        res.ok
          ? res.text()
          : Promise.reject(new Error("Error while updating invoice"))
      );
    },
    {
      onSuccess: (data) => {
        notification.success("Approved successfully");
        queryClient.invalidateQueries(["invoice", invoiceId]);
        queryClient.invalidateQueries(["invoices", currentTeam]);
        queryClient.invalidateQueries(["invoiceApprovers", invoiceId]);
        queryClient.invalidateQueries(["spends"]);
        queryClient.invalidateQueries(["qbPos"]);
        queryClient.invalidateQueries(["exportHistory"]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useBulkApproveInvoices = (currentTeam: string) => {
  const notification = useNotification();
  const { getTokenSilently } = useAuth0();
  return useMutation<any, Error, any>(
    async (approvers: any) => {
      const token = await getTokenSilently();
      return fetch(`/api/v1/invoices/bulk/approve?teamid=${currentTeam}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(approvers),
      }).then((res) =>
        res.ok
          ? res.text()
          : Promise.reject(new Error("Error while updating invoice"))
      );
    },
    {
      onSuccess: (data) => {
        notification.success("Approved successfully");
        queryClient.invalidateQueries(["invoices", currentTeam]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useRejectInvoice = (currentTeam, invoiceId) => {
  const { getTokenSilently } = useAuth0();
  const notification = useNotification();
  return useMutation<any, Error, { invoiceApprover: any }>(
    async ({ invoiceApprover }) => {
      const token = await getTokenSilently();
      return fetch(
        `/api/v1/invoices/${invoiceId}/reject?teamid=${currentTeam}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(invoiceApprover),
        }
      ).then((res) =>
        res.ok
          ? res.text()
          : Promise.reject(new Error("Error while updating invoice"))
      );
    },
    {
      onSuccess: (data) => {
        notification.success("Rejected");
        queryClient.invalidateQueries(["invoice", invoiceId]);
        queryClient.invalidateQueries(["invoices", currentTeam]);
        queryClient.invalidateQueries(["invoiceApprovers", invoiceId]);
        queryClient.invalidateQueries(["spends"]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useUndoApproval = (currentTeam, invoiceId) => {
  const { getTokenSilently } = useAuth0();
  const notification = useNotification();
  return useMutation<any, Error, {}>(
    async (request) => {
      const token = await getTokenSilently();
      return fetch(
        `/api/v1/invoices/${invoiceId}/undo/?teamid=${currentTeam}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(request),
        }
      ).then((res) =>
        res.ok
          ? res.text()
          : Promise.reject(new Error("Error while updating invoice"))
      );
    },
    {
      onSuccess: (data) => {
        notification.success("Success");
        queryClient.invalidateQueries(["invoice", invoiceId]);
        queryClient.invalidateQueries(["invoices", currentTeam]);
        queryClient.invalidateQueries(["invoiceApprovers", invoiceId]);
        queryClient.invalidateQueries(["spends"]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useRetryExport = (currentTeam: string, invoiceId: string) => {
  const { getTokenSilently } = useAuth0();
  const notification = useNotification();
  return useMutation<any, Error, {}>(
    async () => {
      const token = await getTokenSilently();
      return fetch(
        `/api/v1/invoices/${invoiceId}/retry-export/?teamid=${currentTeam}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((res) =>
        res.ok
          ? res.text()
          : Promise.reject(new Error("Error while exporting invoice"))
      );
    },
    {
      onSuccess: (data) => {
        notification.success("Success");
        queryClient.invalidateQueries(["invoice", invoiceId]);
        queryClient.invalidateQueries(["invoices", currentTeam]);
        queryClient.invalidateQueries(["invoiceApprovers", invoiceId]);
        queryClient.invalidateQueries(["spends"]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useRerunWorkflow = (currentTeam: string, invoiceId: string) => {
  const { getTokenSilently } = useAuth0();
  const notification = useNotification();
  return useMutation<any, Error, {}>(
    async () => {
      const token = await getTokenSilently();
      return fetch(
        `/api/v1/invoices/${invoiceId}/rerunWorkflow/?teamid=${currentTeam}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((res) =>
        res.ok
          ? res.text()
          : Promise.reject(new Error("Error while exporting invoice"))
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["invoice", invoiceId]);
        queryClient.invalidateQueries(["invoices", currentTeam]);
        queryClient.invalidateQueries(["invoiceApprovers", invoiceId]);
        queryClient.invalidateQueries(["spends"]);
        notification.success("Reran workflow successfully");
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useFetchUsdAmount = (currentTeam) => {
  const { getTokenSilently } = useAuth0();
  return useMutation<any, Error, { request: any }>(async ({ request }) => {
    const token = await getTokenSilently();
    return fetch(`/api/v1/invoices/currency/convert/?teamid=${currentTeam}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      body: JSON.stringify(request),
    }).then((res) =>
      res.ok
        ? res.json()
        : Promise.reject(new Error("Error while making payment"))
    );
  });
};

export const useDeleteInvoice = (currentTeam) => {
  const { getTokenSilently } = useAuth0();
  return useMutation<any, Error, { ids: any }>(
    async ({ ids }) => {
      const token = await getTokenSilently();

      const invoiceIdsUrl = ids.map((Id) => `&id=${Id}`).join("");
      const deleteUrl = `/api/v1/invoices/?teamid=${currentTeam}${invoiceIdsUrl}`;

      return fetch(deleteUrl, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) =>
        res.ok
          ? Promise.resolve("Deleted")
          : Promise.reject(new Error("Error while deleting the invoice"))
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["invoices", currentTeam]);
        queryClient.invalidateQueries(["spends"]);
      },
      onError: (error) => {},
      onSettled: (data, error) => {},
    }
  );
};

export const usePaymentMethods = (ownerEmail) => {
  const { getTokenSilently } = useAuth0();
  return useQuery<PaymentMethod[]>(["paymentMethods", ownerEmail], async () => {
    const token = await getTokenSilently();
    return fetch(`/api/v1/payments/methods/?teamid=${ownerEmail}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    })
      .then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Error while fetching integrations"))
      )
      .then((c) => (c === null ? [] : c));
  });
};

export const useAddPaymentMethod = (ownerEmail) => {
  const { getTokenSilently } = useAuth0();
  const notification = useNotification();
  return useMutation<any, Error, { paymentMethod: PaymentMethod }>(
    async ({ paymentMethod }) => {
      const token = await getTokenSilently();
      const res = await fetch(
        `/api/v1/payments/methods/?teamid=${ownerEmail}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(paymentMethod),
        }
      );

      if (res.ok) {
        return res.json();
      }

      const errorMessage = await res.text();
      return Promise.reject(new Error(errorMessage));
    },
    {
      onSuccess: (data) => {
        notification.success("Payment method added successfully");
        queryClient.invalidateQueries(["paymentMethods", ownerEmail]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const usePayInvoiceViaWise = (currentTeam) => {
  const notification = useNotification();
  const { getTokenSilently } = useAuth0();
  return useMutation<any, Error, { invoice: any; request: any }>(
    async ({ invoice, request }) => {
      const token = await getTokenSilently();
      const res = await fetch(
        `/api/v1/wisepayment/${invoice.Id}?teamid=${currentTeam}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(request),
        }
      );

      if (res.ok) {
        return res.json();
      }

      const errorMessage = await res.text();
      return Promise.reject(new Error(errorMessage));
    },
    {
      onSuccess: (data) => {
        notification.success("Paid successfully");
        queryClient.invalidateQueries(["invoices", currentTeam]);
        queryClient.invalidateQueries(["wisebalance", currentTeam]);
        queryClient.invalidateQueries(["recipients", ""]);
        queryClient.invalidateQueries(["spends"]);
      },
    }
  );
};

export const useInvoiceApprovers = (invoiceId, currentTeam) => {
  const { getTokenSilently } = useAuth0();
  return useQuery<InvoiceApprover[], Error>(
    ["invoiceApprovers", invoiceId],
    async () => {
      const token = await getTokenSilently();
      return fetch(
        `/api/v1/invoices/${invoiceId}/approvers?teamid=${currentTeam}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Error while fetching integrations"))
      );
    }
  );
};

export const useUpdateInvoiceApprovers = (currentTeam) => {
  const notification = useNotification();
  const { getTokenSilently } = useAuth0();
  return useMutation<any, Error, { request: any }>(
    async ({ request }) => {
      const token = await getTokenSilently();
      return fetch(`/api/v1/invoices/addapprovers?teamid=${currentTeam}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(request),
      }).then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Error while updating invoice"))
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["approvers", currentTeam]);
        queryClient.invalidateQueries(["invoices", currentTeam]);
        queryClient.setQueriesData(["invoice", data.Id], (prevData) => {
          return data;
        });
        notification.success("Approvers updated");
      },
      onError: (error) => {
        console.log("2");
        notification.error(error.message);
      },
    }
  );
};

export const useUpdateInvoiceApprover = (currentTeam, invoiceId) => {
  const notification = useNotification();
  const { getTokenSilently } = useAuth0();
  return useMutation<any, Error, { request: any; approvalRuleId: string }>(
    async ({ request, approvalRuleId }) => {
      const token = await getTokenSilently();
      return fetch(
        `/api/v1/invoices/${invoiceId}/approvers/${approvalRuleId}?teamid=${currentTeam}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(request),
        }
      ).then((res) =>
        res.ok
          ? res.text()
          : Promise.reject(new Error("Error while updating invoice"))
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["invoiceApprovers", invoiceId]);
        queryClient.invalidateQueries(["invoices", currentTeam]);
        notification.success("Approvers updated");
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useAddInvoiceApprover = (currentTeam, invoiceId) => {
  const notification = useNotification();
  const { getTokenSilently } = useAuth0();
  return useMutation<any, Error, { approvalRuleId: any; request: any }>(
    async ({ request, approvalRuleId }) => {
      const token = await getTokenSilently();
      return fetch(
        `/api/v1/invoices/${invoiceId}/approvers/${approvalRuleId}?teamid=${currentTeam}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(request),
        }
      ).then((res) =>
        res.ok
          ? res.text()
          : Promise.reject(new Error("Error while adding invoice approver"))
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["invoiceApprovers", invoiceId]);
        queryClient.invalidateQueries(["invoices", currentTeam]);
        queryClient.invalidateQueries(["invoice", invoiceId]);
        notification.success("Approvers updated");
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useDeleteInvoiceApprover = (currentTeam, invoiceId) => {
  const notification = useNotification();
  const { getTokenSilently } = useAuth0();
  return useMutation<any, Error, { approvalRuleId: any; request: any }>(
    async ({ request, approvalRuleId }) => {
      const token = await getTokenSilently();
      return fetch(
        `/api/v1/invoices/${invoiceId}/approver?teamid=${currentTeam}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(request),
        }
      ).then((res) =>
        res.ok
          ? res.text()
          : Promise.reject(new Error("Error while deleting invoice approver"))
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["invoiceApprovers", invoiceId]);
        queryClient.invalidateQueries(["invoices", currentTeam]);
        queryClient.invalidateQueries(["invoice", invoiceId]);
        notification.success("Approvers updated");
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useRecipients = (currentTeam, currency) => {
  const get = useGet();
  return useQuery<any, Error>(
    ["recipients", currentTeam, currency],
    async () => {
      return get(
        currency
          ? `/wise/accounts?currency=${currency}&teamid=${currentTeam}`
          : `/wise/accounts?teamid=${currentTeam}`
      );
    },
    {
      onSuccess: (data) => {
        //notification.success("Invoice paid successfully");
        queryClient.invalidateQueries(["invoices", currentTeam]);
      },
      onError: (error) => {
        // notification.error(error.message);
      },
    }
  );
};

export const useDeleteIntegration = (teamId) => {
  const { getTokenSilently } = useAuth0();
  return useMutation<any, Error, { id: any }>(
    async ({ id }) => {
      const token = await getTokenSilently();
      return fetch(`/api/v1/externalIntegrations/${id}?teamid=${teamId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) =>
        res.ok
          ? Promise.resolve("Deleted")
          : Promise.reject(new Error("Error while updating vendor"))
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["integrations"]);
        queryClient.invalidateQueries(["sharedDrives"]);
        queryClient.invalidateQueries(["sharedWithMeFolders"]);
      },
      onError: (error) => {},
    }
  );
};

export const useQbProjects = (currentTeam) => {
  const { getTokenSilently } = useAuth0();
  return useQuery<any, Error>(["qbProjects", currentTeam], async () => {
    const token = await getTokenSilently();
    const res = await fetch(
      `/api/v1/invoices/qb/projects/?teamid=${currentTeam}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res.ok) {
      return res.json();
    }

    const errorMessage = await res.text();
    return Promise.reject(new Error(errorMessage));
  });
};

export const useQbClasses = (currentTeam) => {
  const { getTokenSilently } = useAuth0();
  return useQuery<any, Error>(["qbClasses", currentTeam], async () => {
    const token = await getTokenSilently();
    const res = await fetch(
      `/api/v1/invoices/qb/classes/?teamid=${currentTeam}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res.ok) {
      return res.json();
    }

    const errorMessage = await res.text();
    return Promise.reject(new Error(errorMessage));
  });
};

export const useQbLocations = (currentTeam) => {
  const { getTokenSilently } = useAuth0();
  return useQuery<any, Error>(["qbLocations", currentTeam], async () => {
    const token = await getTokenSilently();
    const res = await fetch(
      `/api/v1/invoices/qb/locations/?teamid=${currentTeam}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res.ok) {
      return res.json();
    }

    const errorMessage = await res.text();
    return Promise.reject(new Error(errorMessage));
  });
};

export const useQbPaymentAccounts = (currentTeam) => {
  const { getTokenSilently } = useAuth0();
  return useQuery<any, Error>(["useQBPaymentAccounts", currentTeam], async () => {
    const token = await getTokenSilently();
    const res = await fetch(
      `/api/v1/invoices/qb/accounts/payment?teamid=${currentTeam}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res.ok) {
      return res.json();
    }

    const errorMessage = await res.text();
    return Promise.reject(new Error(errorMessage));
  });
};

export const useQbTerms = (currentTeam) => {
  const { getTokenSilently } = useAuth0();
  return useQuery<any, Error>(["qbTerms", currentTeam], async () => {
    const token = await getTokenSilently();
    const res = await fetch(
      `/api/v1/invoices/qb/terms/?teamid=${currentTeam}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res.ok) {
      return res.json();
    }

    const errorMessage = await res.text();
    return Promise.reject(new Error(errorMessage));
  });
};

export const useQbTaxCodes = (currentTeam) => {
  const { getTokenSilently } = useAuth0();
  return useQuery<QbTaxCode[], Error>(["qbTaxCodes", currentTeam], async () => {
    const token = await getTokenSilently();
    const res = await fetch(
      `/api/v1/invoices/qb/taxcodes/?teamid=${currentTeam}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res.ok) {
      return res.json();
    }

    const errorMessage = await res.text();
    return Promise.reject(new Error(errorMessage));
  });
};

export const useDuplicateInvoices = (invoiceId, currentTeam) => {
  const notification = useNotification();
  const get = useGet();
  return useQuery<any, Error>(
    ["duplicates", invoiceId],
    async () => {
      return get(`invoices/duplicate/${invoiceId}?teamid=${currentTeam}`);
    },
    {
      onError: (error) => {
        notification.error("Error fetching duplicates for invoice");
      },
    }
  );
};

export const usePayments = (invoiceId: string, ownerEmail: string) => {
  const get = useGet();
  return useQuery<PaymentsWrapper, Error>(["payments", invoiceId], () =>
    get(`invoices/${invoiceId}/payments/?teamid=${ownerEmail}`).catch(
      chainError("Error while fetching payments for invoices")
    )
  );
};

export const useAddPayment = (invoiceId: string, ownerEmail: string) => {
  const post = usePost();
  const notification = useNotification();
  return useMutation<unknown, Error, unknown, unknown>(
    ({ request }) =>
      post(`invoices/${invoiceId}/payments/?teamid=${ownerEmail}`, request),
    {
      onSuccess: () => {
        notification.success("Payment recorded successfully");
        queryClient.invalidateQueries(["payments", invoiceId]);
      },
      onError: (err) => {
        notification.error(err.message);
      },
    }
  );
};

export const useLabelValuesFromNanonets = (teamId: string) => {
  const get = useGet();

  return useQueries(
    labelsFromNanonets.map((label) => {
      return {
        queryFn: () => {
          return get(
            `invoices/${label.label}/values/?teamid=${teamId}&type=${label.type}`
          )
            .then((res) => res)
            .catch(chainError("Failed to fetch label values"));
        },
        queryKey: ["labelValuesFromNanonets", teamId, label],
        enabled: teamId === label.email,
      };
    })
  );
};
